<template>
  <BCard>
    <BRow class="justify-between items-center">
      <BCol>
        <strong
          class="text-black font-bold text-md mx-1"
          style="font-size: 16px;"
        >Grafik Registrasi & Komisi Affiliasi</strong>
      </BCol>
      <BCol
        class="text-center flex justify-end"
        md="5"
      >
        <BButton
          id="popoverServiceChart"
          class="btn-sm d-flex w-40 justify-between items-center mr-1"
          type="button"
          aria-expanded="false"
          size="small"
          variant="outline-secondary"
          style="border-color: #e2e2e2 !important; min-width: 100px; max-width: 100px; max-height: 31px !important;"
        >
          <div class="d-flex align-items-center">
            <img
              v-if="isBoostr"
              :src="iconBooster"
              alt="Komship"
              width="100px"
            >
            <img
              v-if="isKomship"
              :src="iconKomship"
              alt="Komship"
              width="100px"
            >
            <img
              v-if="isKompack"
              :src="iconKompack"
              alt="Komship"
              width="100px"
            >
            <img
              v-if="isKomcard"
              :src="iconKomcard"
              alt="Komship"
              width="100px"
            >
          </div>
        </BButton>
        <BPopover
          id="popover-filter-type"
          target="popoverServiceChart"
          triggers="focus"
          placement="bottom"
          style="padding: 0px !important"
        >
          <ul style="margin: -8px -10px -8px -10px;">
            <li
              v-for="(value, idx) in platform"
              :key="idx"
            >
              <BButton
                class="d-flex align-items-center"
                style="padding-left: 0px; width: 100px; height: 31px;"
                variant="flat-dark"
                @click="changePlatform(value.name)"
              >
                <img
                  :src="value.image"
                  alt="Komerce"
                  style="width:100px"
                >
              </BButton>
            </li>
          </ul>
        </BPopover>
        <MonthlyPicker
          v-model="filter"
          date-format="MMM YYYY"
          place-holder="Pilih Bulan"
          :month-labels="monthlabel"
          :max="maxDatePicker"
        />
      </BCol>
    </BRow>
    <BOverlay
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <Apexcharts
        ref="myChart"
        height="230"
        type="area"
        :options="chartOptions"
        :series="chartOptions.series"
      />
    </BOverlay>
  </BCard>
</template>

<script>
import {
  DATE, LABELMONTH, YEAR_MONTH, DAY_MONTH_YEAR,
} from '@/libs/filterDate'
import { affiliateAxiosIns } from '@/libs/axios'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import MonthlyPicker from 'vue-monthly-picker'
import Apexcharts from 'vue-apexcharts'
import moment from 'moment'
import { IDR } from '@/libs/currency'
import { chartOptions } from '../config'

export default {
  components: {
    MonthlyPicker,
    Apexcharts,
  },
  data() {
    return {
      loading: false,
      filter: moment().format('YYYY-MM'),
      monthlabel: LABELMONTH,
      iconKomship: 'https://storage.googleapis.com/komerce/assets/LP-Komclass/boootcamp/logo/komship.png',
      iconBooster: 'https://storage.googleapis.com/komerce/assets/LP-Komclass/boootcamp/logo/Komplace.png',
      iconKompack: 'https://storage.googleapis.com/komerce/assets/LP-Komclass/boootcamp/logo/kompack.png',
      iconKomcard: 'https://storage.googleapis.com/komerce/assets/LP-Komclass/boootcamp/logo/Komcards.png',
      serviceName: 'komship',
      isKomship: true,
      isBoostr: false,
      isKompack: false,
      isKomcard: false,
      platform: [
        {
          image: 'https://storage.googleapis.com/komerce/assets/LP-Komclass/boootcamp/logo/komship.png',
          name: 'komship',
        },
        {
          image: 'https://storage.googleapis.com/komerce/assets/LP-Komclass/boootcamp/logo/Komplace.png',
          name: 'boostr',
        },
        {
          image: 'https://storage.googleapis.com/komerce/assets/LP-Komclass/boootcamp/logo/kompack.png',
          name: 'kompack',
        },
        {
          image: 'https://storage.googleapis.com/komerce/assets/LP-Komclass/boootcamp/logo/Komcards.png',
          name: 'komcard',
        },
      ],
      chartOptions,
    }
  },
  computed: {
    maxDatePicker() {
      return moment().endOf('month')
    },
  },
  watch: {
    filter: {
      handler() {
        this.getData()
        this.changePlatform()
      },
    },
    serviceName: {
      handler(value) {
        if (value === 'komship') {
          this.isKomship = true
          this.isBoostr = false
          this.isKompack = false
          this.isKomcard = false
        } if (value === 'boostr') {
          this.isKomship = false
          this.isBoostr = true
          this.isKompack = false
          this.isKomcard = false
        } if (value === 'kompack') {
          this.isKomship = false
          this.isBoostr = false
          this.isKompack = true
          this.isKomcard = false
        } if (value === 'komcard') {
          this.isKomship = false
          this.isBoostr = false
          this.isKompack = false
          this.isKomcard = true
        }
      },
    },
  },
  mounted() {
    this.getData()
    this.changePlatform()
  },
  methods: {
    async getData() {
      this.loading = true
      const params = {
        month: YEAR_MONTH(this.filter),
        service_name: this.serviceName,
      }
      const url = '/v1/affiliator/admin/chart-specific-month'
      await affiliateAxiosIns.get(url, { params })
        .then(res => {
          const { data } = res.data
          this.chartOptions.series = [
            {
              name: 'Register',
              data: data.map(item => item.count_register),
            },
            {
              name: 'Komisi (Dalam Ribuan)',
              data: data.map(item => item.count_commission / 1000),
            },
          ]
          this.chartOptions = {
            ...this.chartOptions,
            xaxis: {
              ...this.chartOptions.xaxis,
              categories: data.map(item => DATE(item.date)),
              min: data.map(item => item.date),
            },
            yaxis: {
              labels: {
                formatter(value) {
                  if (value < 1) {
                    return (value * 1000).toFixed(0)
                  }
                  return value
                },
              },
            },
          }
          this.loading = false
          this.changePlatform()
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.message,
              variant: 'danger',
            },
          }, 2000)
          this.loading = false
        })
    },
    changePlatform(params) {
      if (params === 'komship') {
        this.serviceName = 'komship'
        this.isKomship = true
        this.isBoostr = false
        this.isKomcard = false
        this.getData()
      } if (params === 'boostr') {
        this.serviceName = 'boostr'
        this.isKomship = false
        this.isBoostr = true
        this.isKomcard = false
        this.getData()
      } if (params === 'kompack') {
        this.serviceName = 'kompack'
        this.isKomship = false
        this.isKompack = true
        this.isKomcard = false
        this.getData()
      } if (params === 'komcard') {
        this.serviceName = 'komcard'
        this.isKomship = false
        this.isKompack = false
        this.isKomcard = true
        this.getData()
      }
      this.$root.$emit('bv::hide::popover', 'popover-filter-type')
    },
  },
}
</script>
