<template>
  <div>
    <strong class="text-black text-bold text-2xl">Dashboard Affiliate</strong>
    <Cards />
    <List />
  </div>
</template>

<script>
import Cards from './Cards.vue'
import List from './List.vue'

export default {
  components: { Cards, List },
}
</script>
