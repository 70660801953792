<template>
  <BCard class="mt-1">
    <strong class="text-black text-bold text-xl">Performa Pertumbuhan User (Partner)</strong>
    <BRow class="mt-1">
      <BCol
        lg="3"
        class="pr-0"
      >
        <CardUI
          :title="'Register'"
          :loading="loading"
          :count="IDRWithoutLbl(cards.count.count_register)"
          :border-class="'cardRegister'"
          :icon="true"
          :hover-text="'Semua Pendaftar Affiliator'"
        />
      </BCol>
      <BCol
        lg="3"
        class="pr-0"
      >
        <CardUI
          :title="'Verifikasi'"
          :loading="loading"
          :count="IDRWithoutLbl(cards.count.count_verified)"
          :border-class="'cardVerification'"
          :icon="true"
          :hover-text="'Affiliator yang sudah melakukan verifikasi'"
        />
      </BCol>
      <BCol
        lg="3"
        class="pr-0"
      >
        <BCard
          class="cardNewuser"
          body-class="p-1 flex flex-col justify-between"
        >
          <div class="flex items-center mb-[5px]">
            <div class="text-black text-lg">
              Get New User (Partner)
            </div>
            <img
              id="getUser"
              src="https://storage.googleapis.com/komerce/assets/svg/info-circle.svg"
              alt="Komerce"
              variant="outline-primary"
              class="ml-[5px] cursor-pointer"
            >
            <b-tooltip
              target="getUser"
              triggers="hover"
              class="tooltip-inner"
            >
              <span class="text-start d-flex">Afiliator yang sudah mendapatkan member/partner (Member dapat memiliki akun di beberapa platform)</span>
              <div class="d-flex">
                <img
                  src="https://storage.googleapis.com/komerce/assets/svg/Elipseyellow.svg"
                >
                <span class="countKomship mx-[5px]">Komship :</span>
                <span>{{ IDRWithoutLbl(cards.count.count_members.komship || 0) }}</span>
              </div>
              <div class="d-flex">
                <img
                  src="https://storage.googleapis.com/komerce/assets/svg/Elipseorange.svg"
                >
                <span class="countBoostr mx-[5px]">Komplace :</span>
                <span>{{ IDRWithoutLbl(cards.count.count_members.boostr || 0) }}</span>
              </div>
              <div class="d-flex">
                <img
                  src="https://storage.googleapis.com/komerce/assets/svg/Ellipsekompack.svg"
                  width="8px"
                >
                <span class="countBoostr mx-[5px]">Kompack :</span>
                <span>{{ IDRWithoutLbl(cards.count.count_members.kompack || 0) }}</span>
              </div>
              <div class="d-flex items-center">
                <span class="ellipsKomcards" />
                <span class="countBoostr mx-[5px]">Komcards :</span>
                <span>{{ IDRWithoutLbl(cards.count.count_members.komcard || 0) }}</span>
              </div>
            </b-tooltip>
          </div>
          <BSpinner
            v-if="loading"
            small
            variant="primary"
          />
          <div
            v-else
            class="text-black text-2xl font-semibold"
          >
            {{ IDRWithoutLbl(cards.count.count_members.total) }}
          </div>
        </BCard>
      </BCol>
      <BCol lg="3">
        <BCard
          class="cardCommision"
          body-class="p-1 flex flex-col justify-between"
        >
          <div class="flex text-align-left mb-[5px]">
            <div class="text-black text-lg">
              Get Commision
            </div>
            <img
              id="getcom"
              src="https://storage.googleapis.com/komerce/assets/svg/info-circle.svg"
              alt="Komerce"
              variant="outline-primary"
              class="ml-[5px] cursor-pointer"
            >
            <b-tooltip
              target="getcom"
              triggers="hover"
              class="tooltip-inner"
            >
              <span class="text-start d-flex">Afiliator yang membernya telah upgrade atau menyelesaikan 1 orderan (Member dapat memiliki akun di beberapa platform)</span>
              <div class="d-flex">
                <img
                  src="https://storage.googleapis.com/komerce/assets/svg/Elipseyellow.svg"
                >
                <span class="countKomship mx-[5px]">Komship :</span>
                <span>{{ IDRWithoutLbl(cards.count.count_member_with_commission.komship || 0) }}</span>
              </div>
              <div class="d-flex">
                <img
                  src="https://storage.googleapis.com/komerce/assets/svg/Elipseorange.svg"
                >
                <span class="countBoostr mx-[5px]">Komplace :</span>
                <span>{{ IDRWithoutLbl(cards.count.count_member_with_commission.boostr || 0) }}</span>
              </div>
              <div class="d-flex">
                <img
                  src="https://storage.googleapis.com/komerce/assets/svg/Ellipsekompack.svg"
                  width="8px"
                >
                <span class="countBoostr mx-[5px]">Kompack :</span>
                <span>{{ IDRWithoutLbl(cards.count.count_member_with_commission.kompack || 0) }}</span>
              </div>
              <div class="d-flex items-center">
                <span class="ellipsKomcards" />
                <span class="countBoostr mx-[5px]">Komcards :</span>
                <span>{{ IDRWithoutLbl(cards.count.count_member_with_commission.komcard || 0) }}</span>
              </div>
            </b-tooltip>
          </div>
          <BSpinner
            v-if="loading"
            small
            variant="primary"
          />
          <div
            v-else
            class="text-black text-2xl font-semibold"
          >
            {{ IDRWithoutLbl(cards.count.count_member_with_commission.total) }}
          </div>
        </BCard>
      </BCol>
    </BRow>
    <BRow>
      <BCol
        lg="3"
        class="pr-0"
      >
        <BCard
          class="pl-2 mb-2"
          body-class="p-1 flex flex-col justify-between"
          style="height: 150px"
        >
          <div class="flex items-center mb-[5px]">
            <div class="text-black text-lg">
              Komisi Pending
            </div>
            <img
              v-b-tooltip.hover.top="'Komisi yang sudah didapatkan Affiliator, namun belum dicairkan'"
              src="https://storage.googleapis.com/komerce/assets/svg/info-circle.svg"
              alt="Komerce"
              variant="outline-primary"
              class="ml-[5px] cursor-pointer"
            >
          </div>
          <BSpinner
            v-if="loading"
            small
            variant="primary"
          />
          <div
            v-else
            class="text-black text-2xl font-semibold"
          >
            {{ IDR(cards.balance.pending_balance) }}
          </div>
        </BCard>
        <BCard
          class="pl-2 mb-2"
          body-class="p-1 flex flex-col justify-between"
          style="height: 150px"
        >
          <div class="flex items-center mb-[5px]">
            <div class="text-black text-lg">
              Komisi Dicairkan
            </div>
            <img
              v-b-tooltip.hover.top="'Komisi yang sudah dicairkan oleh Affiliator'"
              src="https://storage.googleapis.com/komerce/assets/svg/info-circle.svg"
              alt="Komerce"
              variant="outline-primary"
              class="ml-[5px] cursor-pointer"
            >
          </div>
          <BSpinner
            v-if="loading"
            small
            variant="primary"
          />
          <div
            v-else
            class="text-black text-2xl font-semibold"
          >
            {{ IDR(cards.balance.withdrawal_history_value) }}
          </div>
        </BCard>
      </BCol>
      <BCol lg="9">
        <Chart />
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { IDRWithoutLbl, IDR } from '@/libs/currency'
import { affiliateAxiosIns } from '@/libs/axios'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import Chart from './Chart.vue'
import CardUI from '../CardsUI.vue'

export default {
  name: 'Card',
  components: {
    Chart, CardUI,
  },
  data() {
    return {
      cards: {
        count: {},
        balance: {},
      },
      loading: false,
      IDRWithoutLbl,
      IDR,
    }
  },
  mounted() {
    this.getCount()
    this.getBalance()
  },
  methods: {
    async getCount() {
      this.loading = true
      const url = '/v1/affiliator/admin/count'
      await affiliateAxiosIns.get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.cards.count = data
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.message,
              variant: 'danger',
            },
          }, 2000)
          this.loading = false
        })
    },
    async getBalance() {
      this.loading = true
      const url = '/v1/affiliator/admin/balance'
      await affiliateAxiosIns.get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.cards.balance = data
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.message,
              variant: 'danger',
            },
          }, 2000)
          this.loading = false
        })
    },

  },
}
</script>
<style lang="scss" scoped>
@import '../Dashboard.scss';
.ellipsKomcards {
  height: 8px;
  width: 8px;
  background-color: #808080;
  border-radius: 50%;
  display: inline-block;
}
</style>
